import { configureStore, createSlice } from "@reduxjs/toolkit";

//Firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//Environmental variables
require('dotenv').config();
//Fetch env variables
const {REACT_APP_API_KEY,REACT_APP_AUTH_DOMAIN,REACT_APP_PROJECT_ID,REACT_APP_STORAGE_BUCKET,REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID,REACT_APP_MEASUREMENT_ID, REACT_APP_DATABASE_URL } = process.env;
const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID
};
// Initialize Firebase
//const app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);
//End of Firebase

//QUEUE Components and functions
const QUEUE = [];
let retrievedParas = [];
let initialDequeue = true;

function addtoQueue(words){
    //Add array of words in an object to the queue.
    //Object is retrieved from database.
    //Push multiple arrays
    //words.map(labels => QUEUE.push.apply(QUEUE, labels.words));
    words.words.map(line => QUEUE.push(line));
    //console.log(QUEUE);
}

function dq(){
    const x = QUEUE.shift();//QUEUE[0];
    //console.log("Dequeue value: " + x);
    //console.log(QUEUE);
    return [x, QUEUE[0], QUEUE[1]];
}


//Array that contains all keys on a keyboard
//To make sure that all letters shown on screen are pressable
const ALLOWED_KEYS = [
    '`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=',
    '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+',
    'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '[', ']', '\\',
    'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '{', '}', '|',
    'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ';', '\'',
    'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ':', '"',
    'Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', '/',
    'z', 'x', 'c', 'v', 'b', 'n', 'm', '<', '>', '?', ' '
]

//Slices
const wordSlice = createSlice({
    name: 'words',
    initialState: {
        firstWord: "",
        secondWord: "",
        thirdWord: "",
        currentChar: '',
        inputValue: '',
        holdShift: false,
        queueLength: QUEUE.length,
        allowedKeys: ALLOWED_KEYS,
    },
    reducers: {
        dequeue(state){
            [state.firstWord, state.secondWord, state.thirdWord] = dq(); 
        },
        currentChar(state, action){
            state.currentChar = action.payload;
            console.log("Current char: ", state.currentChar);
        },
        queueLength(state){
            //console.log("Queue Length: ", QUEUE.length);
            state.queueLength = QUEUE.length;
        },
        setInputValue(state, action){
            state.inputValue = action.payload;
            //console.log("Input value stored: ",state.inputValue);
        },
    }
});
const infoSlice = createSlice({
    name: 'info',
    initialState: {
        paragraphs: 0,
        letters: 0,
        words: 0,
        infoRetrieved: false,
    },
    reducers: {
        updateInfo(state, action){
            state.paragraphs = action.payload[0];
            state.letters = action.payload[1];
            state.words = action.payload[2];
            state.infoRetrieved = true;
        }
    }
});
export const wordActions = wordSlice.actions;
export const infoActions = infoSlice.actions;

//Store
const store = configureStore({
    reducer: {
        word: wordSlice.reducer,
        info: infoSlice.reducer,
    }
});

//Fetch env variables
const {REACT_APP_PARAGRAPHS_INFO, REACT_APP_LETTERS_INFO, REACT_APP_WORDS_INFO, REACT_APP_PARAGRAPHS} = process.env;
//Action Creators
export function fetchInfo(){
    return async (dispatch) => {
        async function fetchData(){
            let paragraphsindb, lettersindb, wordsindb;
            const dbRef = ref(getDatabase());
            //Retrieve paragraphs count
            const parasnapshot = await get(child(dbRef, REACT_APP_PARAGRAPHS_INFO));
            if (parasnapshot.exists()) {
                paragraphsindb = parasnapshot.val();
                //console.log("Words in db: ", paragraphsindb);
            } else {
                console.log("No data available");
                return;
            }
            //Retrieve Letters count
            const letterssnapshot = await get(child(dbRef, REACT_APP_LETTERS_INFO));
            if (letterssnapshot.exists()) {
                lettersindb = letterssnapshot.val();
                //console.log("Letters in db: ", lettersindb);
            } else {
                console.log("No data available");
                return;
            }
            //Retrieve words count
            const wordsnapshot = await get(child(dbRef, REACT_APP_WORDS_INFO));
            if (wordsnapshot.exists()) {
                wordsindb = wordsnapshot.val();
                //console.log("Words in db: ", wordsindb);
            } else {
                console.log("No data available");
                return;
            }
            return [paragraphsindb, lettersindb, wordsindb];
        }
        try{
            const info = await fetchData();
            //console.log(info);
            dispatch(infoActions.updateInfo(info));
        }catch(error){
            console.log("error fetching from firebase");
        }
    };
}

export function fetchWords(paragraphs){
    return async (dispatch) => {
        async function fetchData(x){
            let paragraph;
            const dbRef = ref(getDatabase());
            //Retrieve paragraphs count
            const parasnapshot = await get(child(dbRef, REACT_APP_PARAGRAPHS + x));
            if (parasnapshot.exists()) {
                paragraph = parasnapshot.val();
                retrievedParas.push(x);
                //console.log("Words: ", paragraph);
            } else {
                console.log("No data available");
                return;
            }
            return paragraph;
        }
        try{
            let words;
            if(retrievedParas.length === paragraphs){
                retrievedParas = [];
            }
            while(QUEUE.length < 30){
                let x;
                while(true){
                    //Make sure to retrieve new words every queue
                    x = Math.floor(Math.random() * ((paragraphs-1) + 1));
                    if(!retrievedParas.includes(x)){
                        break;
                    }
                }
                //console.log("Retrieving para #", x);
                words = await fetchData(x);
                //console.log("Fetched words: ",words);
                addtoQueue(words);
                //console.log("QUEUE Length: ", QUEUE.length);
            }
            if(initialDequeue){
                dispatch(wordActions.dequeue());
                initialDequeue = false;
            }
            dispatch(wordActions.queueLength());
        }catch(error){
            console.log("error fetching from firebase");
        }
    };
}

export default store;