import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
//import App from './App';
import {Provider} from 'react-redux'
import store from './store/store'
import reportWebVitals from './reportWebVitals';
const App = React.lazy(() => import('./App'));
const What = React.lazy(() => import('./components/What'));
const Hand = React.lazy(() => import('./components/Hand'));
const WordBank = React.lazy(() => import('./components/WordBank'));
const How = React.lazy(() => import('./components/How'));

ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={null}>
          <App />
        </Suspense>
    </React.StrictMode>
    </Provider>,
  document.getElementById('root')
);
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <Hand />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('hand')
);
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <What />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('what')
);
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense fallback={null}>
        <WordBank />
      </Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById('wordbank')
);
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <How />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('how')
);
reportWebVitals();